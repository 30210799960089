.hype-container {
  margin-bottom: 5rem;
}
.hype-title {
  text-align: center;
}
.hype-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hype-content-container a {
  margin: 1rem;
}

.portfolio-container {
  text-align: center;
}
.portfolio-content-logo {
  margin-top: 5rem;
}
.portfolio-content-list a {
  margin: 1rem;
}

@media only screen and (max-width: 768px) {
  .hype-title img {
    width: 12rem;
  }
  .hype-content-container {
    flex-direction: column;
    /* justify-content: center; */
  }
  .hype-content-container a {
    margin: .5rem;
  }
  .hype-content-container a img {
    width: 20em;
  }
  .portfolio-content-logo img {
    width: 6rem;
  }
  .portfolio-content-list a img {
    width: 6rem;
  }
  .portfolio-content-logo {
    margin-top: 1rem;
  }
}