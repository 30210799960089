.client-container {
  background-image: url("https://bucket.tms.id/media/client-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  padding-top: 14rem;
  padding-bottom: 14rem;
}
.client-title {
  text-align: center;
}
.client-content {
  text-align: center;
  margin: 0 2rem;
}

@media only screen and (max-width: 768px) {
  .client-title img {
    width: 20rem;
  }
  .client-content {
    margin: 0 .4rem;
  }
  .client-content img {
    width: 4rem;
  }
  .client-container {
    background-image: url("https://bucket.tms.id/media/client-bg-mobile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}