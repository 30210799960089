.service-container {
  text-align: center;
}
.service-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.smm {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .service-title {
    margin-top: -4rem;
  }
  .service-title img {
    width: 22rem;
  }
  .service-content span img {
    width: 8rem;
  }
}