/* 
.nav-item{
  display: flex;
  align-items: center;
}
.nav-item ul {
  display: flex;
  justify-content: space-around;
  margin-right: 1rem;
}
.nav-item ul li {
  padding: 1rem;
  font-size: small;
} */
.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  color: #FFF;
  height: 3rem;
  z-index: 1;
}
.nav-dark {
  transition: ease-in 1s ;
  background: linear-gradient(90deg, rgba(87,10,87,1) 0%, rgba(46,2,73,1) 100%);
}
.logo {
  margin: .5rem 3rem;
  display: flex;
  align-items: center;
}
.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-right: 2rem;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}
.menu > li a {
  list-style: none;
  text-decoration: none;
  color: white;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 768px) {
  .menu-button-container {
    display: flex;
    padding-right: 3rem;
  }
  .menu {
    position: absolute;
    top: -8px;
    margin-top: 50px;
    right: 34px;
    flex-direction: column;
    width: 20%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    height: 2em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #1F082D;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}