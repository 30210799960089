.title-container {
  text-align: center;
}

.title-container p {
  font-size: 40px;
}

.report-container {
  padding: 0 2rem;
  height: 80vh;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.report-card {
  height: auto;
  background-color: white;
  padding: 1rem;
  border-radius: 15px;
}

.title {
  font-size: larger;
  font-weight: 700;
}

.image {
  object-fit: contain;
}

.button-report {
  margin-top: 5rem;
  background-color: #6D2A78;
  color: white;
  text-decoration: none;
  padding: .5rem;
  border-radius: 10px;
}