/* * {
  margin: 0;
  padding: 0;
} */

body {
  background-color: #777;
  max-width: 100%;
  overflow-x: hidden;
}

html {
  font-family: 'Merriweather', serif;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('/public/assets/Fonts/SF-Pro-Rounded-Regular.otf');
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14.45px;
  }
}

@media only screen and (min-width: 1366px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 19px;
  }
}
@media only screen and (min-width: 2560px) {
  html {
    font-size: 27px;
  }
}