.hero-container{
  margin-bottom: 5rem;
}
.video-preview {
  min-width: 100%;
  min-height: 100%;
}

@media only screen and (max-width: 768px) {
  .video-preview {
    height: 20%;
    width: 20%;
  }
}