.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #2A0048;
  border-radius: 30px;
  padding: 1rem;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 36, 36, 0.8);
}

.modal-content {
  display: flex;
  width: 1200px;
}
.modal-desc {
  color: rgb(214, 214, 214);
  margin: 1rem 1rem 0 2rem;
  text-align: justify;
  line-height: 1.6;
}
.modal-desc h4 {
  font-size: 1.5rem;
}
.modal-desc span {
  font-size: 1.4rem;
}
.modal-desc ul li{
  font-size: 1.2rem;
}
.btn-close {
  display: none;
}

@media only screen and (max-width: 768px) {
  .modal {
    width: 80%;
    border: none;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .modal-content-image {
    display: flex;
    justify-content: center;
  }
  .modal-content img {
    width: 10rem;
    height: 10rem;
  }
  .modal-desc h4 {
    font-size: 1rem;
  }
  .modal-desc span {
    font-size: .9rem;
  }
  .modal-desc ul li{
    font-size: .9rem;
  }
}