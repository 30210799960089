.footer {
  display: none;
}
.footer-dark {
  background: linear-gradient(90deg, rgba(87,10,87,1) 0%, rgba(46,2,73,1) 100%);
  position: fixed;
  width: 100%;
  height: 7%;
  bottom: 0;
  transition-timing-function: ease-in;
  transition: all 0.5s ;
}
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.footer-content span {
  margin: 1.5rem 3rem;
  font-weight: 100;
  font-size: small;
  position: absolute;
  left: 0;
}
.sosmed-content img {
  margin: .8rem;
}

@media only screen and (max-width: 768px) {
  .footer-dark {
    min-height: 5%;
  }
  .footer-content {
    justify-content: center;
    align-items: center;
  }
  .footer-content span {
    position: unset;
    margin: 1rem;
  }
  .sosmed-content img {
    margin: .2rem;
    min-width: 1rem;
  }

}