.contact-container {
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 5rem;
}
.contact-title span {
  color: #fff;
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    margin-top: 2rem;
    text-align: center;
    /* height: 15vh; */
  }
  .contact-title img {
    width: 24rem;
  }
  .contact-title span {
    font-size: 1rem;
  }
}